/* eslint-disable readme-internal/no-restricted-imports */
import type { Hub2UserDocument } from '@readme/backend/models/hub2user/types';
import type { ProjectDocument } from '@readme/backend/models/project/types';
import type { MetricsUserVariable } from '@readme/server-shared/metrics/types';
import type { Har } from 'har-format';
import type { Operation } from 'oas/operation';

import { maskCredential } from '@readme/server-shared/metrics/mask-credential';
import { getRequestGroup, processTryItRequest } from '@readme/server-shared/metrics/zero-config';
import { useContext, useEffect, useState, useMemo, useCallback } from 'react';

import { VariablesContext, ProjectContext, UserContext } from '@core/context';
import useEnvInfo from '@core/hooks/useEnvInfo';
import { useMetricsAPIFetcher } from '@core/hooks/useMetricsAPI';
import { useReferenceStore } from '@core/store';

const proxyPrefix = 'https://try.readme.io/';

/**
 * With a HAR and a response from an API request, log an entry into Metrics for zero-config usage.
 */
function useZeroConfigMetrics(
  /**
   * An `Operation` class instance. We use its security scheme data to construct the API request log.
   */
  operation: Operation,
) {
  const groupId = useReferenceStore(s => s.auth.group);
  const variables = useContext(VariablesContext) as unknown as MetricsUserVariable;
  const { project } = useContext(ProjectContext) as unknown as { project: ProjectDocument };
  const { user } = useContext(UserContext) as unknown as Hub2UserDocument;

  const [request, recordMetrics] = useState<{ har: Har; res: Response } | null>(null);

  const { isProd } = useEnvInfo();
  const metricsFetch = useMetricsAPIFetcher();

  const resClone = useMemo(() => request?.res.clone(), [request?.res]);

  const captureRequest = useCallback(
    async (subdomain, harEntry, group, res) => {
      const body = await processTryItRequest(subdomain, harEntry, group, res);

      return metricsFetch({
        path: 'create/request',
        method: 'POST',
        body,
      });
    },
    [metricsFetch],
  );

  useEffect(() => {
    // TODO figure out a way to implement zero config not through front end
    if (request && resClone) {
      const { har } = request;
      if (!har.log || !har.log.entries || !har.log.entries[0] || !har.log.entries[0].request) {
        return;
      }

      const harEntry = har.log.entries[0];
      const requestUrl = har.log.entries[0].request.url;

      // Filter out non-proxied requests.
      if (!requestUrl.startsWith(proxyPrefix) && isProd) {
        return;
      }

      const mergedHeaders = harEntry?.request?.headers
        .map(header => {
          const { name } = header;
          if (name?.toLowerCase() !== 'authorization') return header;
          // If authorization header, ensure that we hash it
          return { name, value: maskCredential(header.value) };
        })
        .concat([{ name: 'User-Agent', value: 'ReadMe-API-Explorer' }]);

      harEntry.request.headers = mergedHeaders;
      // Remove the `try.readme.io` proxy from the URL.
      harEntry.request.url = requestUrl.replace(proxyPrefix, '');

      const { id, label, email = user?.email } = getRequestGroup(variables, groupId, operation);
      const group = { id, label, email };

      captureRequest(project.subdomain, harEntry, group, resClone);
    }
  }, [resClone, isProd, request, variables, project.subdomain, user?.email, operation, captureRequest, groupId]);

  return { recordMetrics };
}

export default useZeroConfigMetrics;
