import type { MetricsUserVariable, Group, ReadMeApiLog } from './types';
import type { Entry, Response as HarResponse } from 'har-format';
import type { Operation } from 'oas/operation';

import { getGroupId } from './getGroupId';
import { maskCredential, checkHash } from './mask-credential';
import { ZERO_CONFIG_CREATOR } from './types';

export const getRequestGroup = (variables: MetricsUserVariable, groupId?: string, operation?: Operation) => {
  // Validate that all groupId values are hashed, if they're not, we must hash them here.
  const shouldHash = !checkHash(groupId);

  // If no user (logged out), return basic grouping function
  if (!Object.keys(variables?.user || {}).length) {
    const id = shouldHash ? maskCredential(groupId) : groupId ?? '';
    return { label: '', email: '', id };
  }

  const { user } = variables ?? {};
  const apiKey = (groupId || getGroupId(user, operation)) as string;
  const label = (user.label || user.name || '') as string;
  const email = user.email as string;

  return { id: shouldHash ? maskCredential(apiKey) : apiKey, label, email };
};

export const transformHeaders = (originalHeaders: Headers) => {
  if (!originalHeaders) return [];

  const headers: { name: string; value: string }[] = [];

  // reformat headers for expected format by metrics
  for (const entry of originalHeaders.entries()) {
    if (entry.length === 2) {
      const [name, value] = entry;
      headers.push({ name, value });
    }
  }

  return headers;
};

const createApiLog = (
  logResponse: Response,
  responseBody: string,
  harEntry: Entry,
  group: Group,
  subdomain: string,
): ReadMeApiLog => {
  const creator = { name: ZERO_CONFIG_CREATOR, version: '1.0' };

  const response: Partial<HarResponse> = {
    status: logResponse.status,
    statusText: logResponse.statusText,
    headers: transformHeaders(logResponse.headers),
    content: {
      mimeType: logResponse.headers['content-type'] || 'application/json',
      size: responseBody.length,
      text: responseBody,
    },
  };

  const entries: Partial<Entry>[] = [
    {
      time: 1,
      startedDateTime: new Date().toString(),
      request: harEntry.request,
      response: response as HarResponse,
    },
  ];

  return {
    subdomain,
    log: {
      development: false,
      group,
      request: { log: { creator, entries } },
      _version: 3, // Version 3 of the request body includes credential hashing
    },
  };
};

export const processTryItRequest = async (subdomain: string, harEntry: Entry, group: Group, logResponse: Response) => {
  let body = '';

  try {
    body = await logResponse.text();
    return createApiLog(logResponse, body, harEntry, group, subdomain);
  } catch (e) {
    return createApiLog(logResponse, body, harEntry, group, subdomain);
  }
};
